import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SectionBlingComponent from './sectionBling';

const SectionBling = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { home },
      },
    }) => <SectionBlingComponent {...props} wordings={{ ...home }} />}
    query={graphql`
      query SectionBling {
        wordings {
          fr {
            home {
              bling_title
              bling_desc
            }
          }
        }
      }
    `}
  />
);

export default SectionBling;
