import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SectionWeatherComponent from './sectionWeather';

const SectionWeather = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { home },
      },
    }) => <SectionWeatherComponent {...props} wordings={{ ...home }} />}
    query={graphql`
      query SectionWeather {
        wordings {
          fr {
            home {
              weather_title_line1
              weather_title_line2
              weather_title_line3
              weather_title_line4
              weather_desc
              alarm_title_line1
              alarm_title_line2
              alarm_title_line3
              alarm_title_line4
              alarm_desc
            }
          }
        }
      }
    `}
  />
);

export default SectionWeather;
