import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SectionMagotComponent from './sectionMagot';

const SectionMagot = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { home },
      },
    }) => <SectionMagotComponent {...props} wordings={{ ...home }} />}
    query={graphql`
      query SectionMagot {
        wordings {
          fr {
            home {
              magot_title
              magot_subtitle
              magot_desc
            }
          }
        }
      }
    `}
  />
);

export default SectionMagot;
