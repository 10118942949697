import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { VideoComponent, ParallaxComponent } from '../../common';

import './styles.styl';

import mobileCoverImage from '../../../assets/img/home/bling_mobile.png';
import blingVideo from '../../../assets/videos/screen_bling.mp4';
import blingHandImage from '../../../assets/img/home/bling-phone.png';

export default class SectionBling extends Component {
  renderImages() {
    const { isVisible } = this.props;

    return (
      <div className="overflow-wrapper">
        <img
          src={mobileCoverImage}
          alt=""
          className="mobile-only mobile-cover"
        />
        <VideoComponent autoPlay muted loop isVisible={isVisible}>
          <source src={blingVideo} type="video/mp4" />
        </VideoComponent>
        <img className="hand mobile-hidden" src={blingHandImage} alt="" />
      </div>
    );
  }

  render() {
    const { wordings, isVisible } = this.props;

    return (
      <section className="bling">
        {this.renderImages()}
        <div className="sneak-peek">
          <div className="wrapper">
            <ParallaxComponent index={10} skewable isVisible={isVisible}>
              <h1>{wordings.bling_title}</h1>
            </ParallaxComponent>
            <p>{wordings.bling_desc}</p>
          </div>
        </div>
      </section>
    );
  }
}

SectionBling.propTypes = {
  wordings: PropTypes.shape({}).isRequired,
  isVisible: PropTypes.bool.isRequired,
};
