import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SectionIntroComponent from './sectionIntro';

const SectionIntro = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { home },
      },
    }) => <SectionIntroComponent {...props} wordings={{ ...home }} />}
    query={graphql`
      query SectionIntro {
        wordings {
          fr {
            home {
              intro_title_left
              intro_title_right
              intro_subtitle
              intro_desc
            }
          }
        }
      }
    `}
  />
);

export default SectionIntro;
