/* eslint-disable react/no-array-index-key */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Siema from 'siema';

import { CAROUSEL_VIDEOS } from './constants';

import './styles.styl';
import playIcon from '../../../assets/img/icons/play.svg';
import { ParallaxComponent, VideoComponent } from '../../common';

export default class SectionDemo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      areVideosMute: true,
      areVideosPlaying: false,
      currentVideoIndex: 0,
      titlePosition: 0, // Handle legacy issue with title. Check monimalz-web-v2 for reference
    };

    this.sectionRef = React.createRef();
    this.videosRef = React.createRef();
    this.carouselRef = React.createRef();

    this.siema = null;
  }

  componentDidMount() {
    if (this.carouselRef.current) {
      this.siema = new Siema({
        selector: this.carouselRef.current,
        duration: 400, // TODO : Externalize
        easing: 'cubic-bezier(.17,.67,.32,1.34)',
        perPage: 1,
        startIndex: 0,
        draggable: true,
        multipleDrag: true,
        threshold: 20,
        loop: true,
        rtl: false,
        onChange: () => {
          this.setState({ currentVideoIndex: this.siema.currentSlide });
        },
      });
    }
    // Set title position
    if (this.sectionRef.current && this.videosRef.current) {
      this.setState({
        titlePosition:
          (this.sectionRef.current.offsetHeight -
            this.videosRef.current.offsetHeight) *
          0.75,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;

    // Stop videos when they are out of the screen
    if (prevProps.isVisible !== isVisible) {
      this.onVisibilityChange();
    }
  }

  onVisibilityChange() {
    const { isVisible } = this.props;

    if (!isVisible) {
      this.setState({ areVideosPlaying: false });
    }
  }

  onMuteClick() {
    const { areVideosMute } = this.state;

    this.setState({ areVideosMute: !areVideosMute });
  }

  onPlayClick() {
    const { areVideosPlaying } = this.state;

    this.setState({ areVideosPlaying: !areVideosPlaying });
  }

  onDotClick(index) {
    if (this.siema) {
      this.siema.goTo(index);
    }
  }

  renderVideo({ mobile, desktop, key }, index) {
    const { isVisible, isMobile } = this.props;
    const { areVideosMute, areVideosPlaying, currentVideoIndex } = this.state;

    return (
      <div key={key} className="wrapper">
        <div className="icon-container">
          <div className="play-icon" onClick={() => this.onPlayClick()}>
            <img src={playIcon} alt="play" />
          </div>
          <div
            className={`mute-button ${areVideosMute ? 'muted' : ''}`}
            onClick={() => this.onMuteClick()}
          />
        </div>
        <VideoComponent
          playsInline
          isVisible={isVisible}
          isPlaying={areVideosPlaying && currentVideoIndex === index}
          muted={areVideosMute}
          background={isMobile ? mobile.png : desktop.png}
        >
          {isMobile ? (
            <Fragment>
              <source src={mobile.mp4} type="video/mp4" />
              <source src={mobile.webm} type="video/webm" />
            </Fragment>
          ) : (
            <Fragment>
              <source src={desktop.mp4} type="video/mp4" />
              <source src={desktop.webm} type="video/webm" />
            </Fragment>
          )}
        </VideoComponent>
      </div>
    );
  }

  renderVideos() {
    return (
      <div className="overflow-wrapper grabbable" ref={this.videosRef}>
        <div className="siema-wrapper" ref={this.carouselRef}>
          {CAROUSEL_VIDEOS.map((video, index) =>
            this.renderVideo(video, index)
          )}
        </div>
      </div>
    );
  }

  renderDots() {
    const { currentVideoIndex } = this.state;

    return (
      <div className="dots">
        {CAROUSEL_VIDEOS.map((video, index) => (
          <div
            key={index}
            className={`dot ${index === currentVideoIndex ? 'active' : ''}`}
            onClick={() => {
              this.onDotClick(index);
            }}
          />
        ))}
      </div>
    );
  }

  render() {
    const { wordings, isVisible, isMobile } = this.props;
    const { areVideosPlaying, titlePosition } = this.state;

    return (
      <section
        className={`demo ${areVideosPlaying ? 'playing' : ''}`}
        ref={this.sectionRef}
      >
        {this.renderVideos()}
        <ParallaxComponent index={10} skewable isVisible={isVisible}>
          <div className="title-wrapper">
            <h4
              style={{
                bottom: `${isMobile ? 0 : -titlePosition}px`,
              }}
            >
              {wordings.demo_title}
            </h4>
            <h1
              style={{
                bottom: `${isMobile ? 0 : -titlePosition}px`,
              }}
            >
              {wordings.demo_subtitle}
            </h1>
          </div>
        </ParallaxComponent>
        {this.renderDots()}
      </section>
    );
  }
}

SectionDemo.propTypes = {
  wordings: PropTypes.shape({}).isRequired,
  isVisible: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
