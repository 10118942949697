import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SectionHopComponent from './sectionHop';

const SectionHop = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { home },
      },
    }) => <SectionHopComponent {...props} wordings={{ ...home }} />}
    query={graphql`
      query SectionHop {
        wordings {
          fr {
            home {
              hop_title
              hop_subtitle
              hop_desc
            }
          }
        }
      }
    `}
  />
);

export default SectionHop;
