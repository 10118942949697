import React from 'react';
import PropTypes from 'prop-types';

import { VideoComponent, ParallaxComponent } from '../../common';

import './styles.styl';

import mobileImage from '../../../assets/img/home/mobile_mission.jpg';
import missionVideo from '../../../assets/videos/screen_bling.mp4';
import phoneImage from '../../../assets/img/home/missions-phone.png';

const SectionMission = ({ wordings, isVisible }) => (
  <section className="mission">
    <ParallaxComponent index={14} isVisible={isVisible}>
      <div className="mission-wrapper">
        <img src={mobileImage} alt="" className="mobile-only mobile-mission" />
        <VideoComponent
          autoPlay
          muted
          loop
          src={missionVideo}
          isVisible={isVisible}
        >
          <source src={missionVideo} type="video/mp4" />
        </VideoComponent>
        <img className="phone" src={phoneImage} alt="" />
      </div>
    </ParallaxComponent>

    <div className="sneak-peek">
      <div className="wrapper">
        <ParallaxComponent index={6} skewable isVisible={isVisible}>
          <h2>{wordings.mission_subtitle}</h2>
          <h1 dangerouslySetInnerHTML={{ __html: wordings.mission_title }} />
        </ParallaxComponent>
        <p>{wordings.mission_desc}</p>
      </div>
    </div>
  </section>
);

SectionMission.propTypes = {
  wordings: PropTypes.shape({}).isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default SectionMission;
