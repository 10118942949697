import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SectionMessageComponent from './sectionMessage';

const SectionMessage = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { home },
      },
    }) => <SectionMessageComponent {...props} wordings={{ ...home }} />}
    query={graphql`
      query SectionMessage {
        wordings {
          fr {
            home {
              message_title_line1
              message_title_line2
              message_desc
            }
          }
        }
      }
    `}
  />
);

export default SectionMessage;
