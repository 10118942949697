/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { withPrefix } from 'gatsby';
import lottie from 'lottie-web';

import './styles.styl';

const HOME_LOADER_LAYER = [
  { color: ' #1AF6C0', className: 'layer left', delay: '0.6s' },
  { color: ' #1AF6C0', className: 'layer right', delay: '0.6s' },
  { color: ' #3C1A68', className: 'layer left', delay: '0.4s' },
  { color: ' #3C1A68', className: 'layer right', delay: '0.4s' },
  { color: ' #F86176', className: 'layer left', delay: '0.2s' },
  { color: ' #F86176', className: 'layer right', delay: '0.2s' },
  { color: ' #FFDB72', className: 'layer left', delay: '0s' },
  { color: ' #FFDB72', className: 'layer right', delay: '0s' },
];

class HomeLoader extends Component {
  constructor(props) {
    super(props);
    this.player = React.createRef();
    this.animation = null;

    this.state = {
      loaderHidden: false,
    };
  }

  componentDidMount() {
    if (this.player.current) {
      this.animation = lottie.loadAnimation({
        container: this.player.current, // the dom element that will contain the animation
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: withPrefix('/animations/logo_monimalz_loader.json'),
      });
      this.animation.addEventListener('complete', () => {
        this.startAnimation();
      });
      this.animation.play();
    }
  }

  startAnimation() {
    window.setTimeout(() => {
      this.setState({ loaderHidden: true }); // TODO: rename hidden by something more corresponding, it's not hidden at all !!
      window.setTimeout(() => {
        this.props.onEnd();
      }, 2300);
    }, 3000); // TODO: externalize
  }

  render() {
    const { loaderHidden } = this.state;

    return (
      <div className={`loader ${loaderHidden ? 'hidden' : ''}`}>
        {HOME_LOADER_LAYER.map(({ color, className, delay }, index) => (
          <div
            className={className}
            style={{ backgroundColor: color, transitionDelay: delay }}
            key={index}
          />
        ))}
        <div ref={this.player} className="player" />
      </div>
    );
  }
}

export default HomeLoader;
