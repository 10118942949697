/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { requestMonimalzProducts } from '../actions/shopActions';
import { onWelcomeAnimationEnd } from '../actions/appActions';

import Layout from '../components/layout/layout';
import {
  NavBar,
  Menu,
  Footer,
  Basket,
  pageWithNavigation,
} from '../components/common';
import HomeSections from '../components/home';
import HomeLoader from '../components/home/homeLoader/homeLoader';

class IndexPage extends Component {
  componentDidMount() {
    // TODO : Request only the basket, items aren't displayed on the home
    this.props.requestMonimalzProducts();
  }

  render() {
    const { isMobile, welcomeAnimationPlayed, data } = this.props;

    return (
      <Layout
        isLocked={!welcomeAnimationPlayed}
        title={data.wordings.fr.home.page_title}
        description={data.wordings.fr.home.description}
      >
        {!welcomeAnimationPlayed ? (
          <HomeLoader onEnd={this.props.onWelcomeAnimationEnd} />
        ) : null}
        <NavBar />
        <Basket />
        <Menu />
        <HomeSections
          isMobile={isMobile}
          welcomeAnimationPlayed={welcomeAnimationPlayed}
        />
        <Footer />
      </Layout>
    );
  }
}

const mapStateToProps = ({ app: { welcomeAnimationPlayed } }) => ({
  welcomeAnimationPlayed,
});

IndexPage.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  welcomeAnimationPlayed: PropTypes.bool.isRequired,
  requestMonimalzProducts: PropTypes.func.isRequired,
  onWelcomeAnimationEnd: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
};

export const query = graphql`
  query HomePageQuery {
    wordings {
      fr {
        home {
          page_title
          description
        }
      }
    }
  }
`;

export default connect(
  mapStateToProps,
  { requestMonimalzProducts, onWelcomeAnimationEnd }
)(pageWithNavigation(IndexPage));
