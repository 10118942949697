import jsDeskWebm from '../../../assets/videos/section-demo/js-desk.webm';
import jsDeskMp4 from '../../../assets/videos/section-demo/js-desk.mp4';
import jsDeskPng from '../../../assets/videos/section-demo/js-desk.png';

import jsMobWebm from '../../../assets/videos/section-demo/js-mob.webm';
import jsMobMp4 from '../../../assets/videos/section-demo/js-mob.mp4';
import jsMobPng from '../../../assets/videos/section-demo/js-mob.png';

import scDeskWebm from '../../../assets/videos/section-demo/sc-desk.webm';
import scDeskMp4 from '../../../assets/videos/section-demo/sc-desk.mp4';
import scDeskPng from '../../../assets/videos/section-demo/sc-desk.png';

import scMobWebm from '../../../assets/videos/section-demo/sc-mob.webm';
import scMobMp4 from '../../../assets/videos/section-demo/sc-mob.mp4';
import scMobPng from '../../../assets/videos/section-demo/sc-mob.png';

// eslint-disable-next-line import/prefer-default-export
export const CAROUSEL_VIDEOS = [
  {
    desktop: {
      webm: jsDeskWebm,
      png: jsDeskPng,
      mp4: jsDeskMp4,
    },
    mobile: {
      webm: jsMobWebm,
      png: jsMobPng,
      mp4: jsMobMp4,
    },
    key: 'js',
  },
  {
    desktop: {
      webm: scDeskWebm,
      png: scDeskPng,
      mp4: scDeskMp4,
    },
    mobile: {
      webm: scMobWebm,
      png: scMobPng,
      mp4: scMobMp4,
    },
    key: 'sc',
  },
];
