import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ParallaxComponent } from '../../common';

import './styles.styl';

import pieceVideoMp4 from '../../../assets/videos/piece.mp4';
import pieceVideoWebM from '../../../assets/videos/piece.webm';
import hopMobileImage from '../../../assets/img/home/hop_mobile_cover.png';

export default class SectionHop extends Component {
  renderVideoDesktop() {
    const { isMobile } = this.props;

    if (!isMobile) {
      return (
        <ParallaxComponent index={10}>
          <video autoPlay muted loop>
            <source src={pieceVideoMp4} type="video/mp4" />
            <source src={pieceVideoWebM} type="video/ogg" />
          </video>
        </ParallaxComponent>
      );
    }

    return null;
  }

  renderCoverMobile() {
    const { isMobile } = this.props;

    if (isMobile) {
      return (
        <ParallaxComponent index={15}>
          <img className="mobile-cover" src={hopMobileImage} alt="" />
        </ParallaxComponent>
      );
    }

    return null;
  }

  render() {
    const { wordings } = this.props;

    return (
      <section className="hop">
        <div className="sneak-peek">
          <div className="wrapper">
            <ParallaxComponent index={6} skewable>
              <h1>{wordings.hop_title}</h1>
            </ParallaxComponent>
            <div>
              <h2>{wordings.hop_subtitle}</h2>
            </div>
            <p>{wordings.hop_desc}</p>
          </div>
        </div>

        {this.renderVideoDesktop()}
        {this.renderCoverMobile()}
      </section>
    );
  }
}

SectionHop.propTypes = {
  wordings: PropTypes.shape({}).isRequired,
  isMobile: PropTypes.bool.isRequired,
};
