import React from 'react';
import PropTypes from 'prop-types';

import { VideoComponent, ParallaxComponent } from '../../common';

import './styles.styl';

import mobileCoverImage from '../../../assets/img/home/mobile-cover-magot.png';
import magotVideoMp4 from '../../../assets/videos/magot.mp4';
import magotVideoWebm from '../../../assets/videos/magot.webm';

const SectionMagot = ({ wordings, isVisible }) => (
  <section className="magot">
    <div className="wrapper">
      <ParallaxComponent index={8} skewable isVisible={isVisible}>
        <h2>{wordings.magot_title}</h2>
      </ParallaxComponent>
      <ParallaxComponent index={10} skewable isVisible={isVisible}>
        <h1>{wordings.magot_subtitle}</h1>
      </ParallaxComponent>
      <p dangerouslySetInnerHTML={{ __html: wordings.magot_desc }} />
    </div>

    <img src={mobileCoverImage} alt="" className="cover-mobile mobile-only" />

    <VideoComponent className="hand" autoPlay muted loop isVisible={isVisible}>
      <source src={magotVideoMp4} type="video/mp4" />
      <source src={magotVideoWebm} type="video/ogg" />
    </VideoComponent>
  </section>
);

SectionMagot.propTypes = {
  wordings: PropTypes.shape({}).isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default SectionMagot;
