import React from 'react';
import Proptypes from 'prop-types';

import { ParallaxComponent, VideoComponent } from '../../common';

import storyVideo from '../../../assets/videos/masques.mp4';
import storyMasque from '../../../assets/img/home/masques.png';
import storyMobile from '../../../assets/img/home/mobile_story.png';

import './styles.styl';

const SectionStory = ({ wordings, isVisible }) => (
  <section className="story">
    <ParallaxComponent index={5}>
      <div className="phone-wrapper">
        <img src={storyMasque} alt="masque pour la video" />
        <VideoComponent autoPlay loop muted isVisible={isVisible}>
          <source src={storyVideo} type="video/mp4" />
        </VideoComponent>
      </div>
    </ParallaxComponent>

    <ParallaxComponent index={6} className="image-wrapper mobile-only">
      <img src={storyMobile} alt="fond pour mobile" />
    </ParallaxComponent>

    <div className="sneak-peek">
      <ParallaxComponent index={6} skewable>
        <h1>{wordings.story_title}</h1>
      </ParallaxComponent>
      <div className="wrapper">
        <h2>{wordings.story_subtitle}</h2>
        <p>{wordings.story_desc}</p>
      </div>
    </div>
  </section>
);

SectionStory.propTypes = {
  wordings: Proptypes.shape({}).isRequired,
  isVisible: Proptypes.bool.isRequired,
};

export default SectionStory;
