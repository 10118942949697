import imageCode from '../../../assets/img/home/apps/app_01.png';
import imageDream from '../../../assets/img/home/apps/app_02.png';
import imageDance from '../../../assets/img/home/apps/app_03.png';
import imageLight from '../../../assets/img/home/apps/app_04.png';
import imageRaise from '../../../assets/img/home/apps/app_05.png';

// eslint-disable-next-line import/prefer-default-export
export const PROGRAM_SLIDES = [
  {
    titleWordingKey: 'program_code_title',
    subtitleWordingKey: 'program_code_subtitle',
    image: imageCode,
    key: 'code',
  },
  {
    titleWordingKey: 'program_dream_title',
    subtitleWordingKey: 'program_dream_subtitle',
    image: imageDream,
    key: 'dream',
  },
  {
    titleWordingKey: 'program_dance_title',
    subtitleWordingKey: 'program_dance_subtitle',
    image: imageDance,
    key: 'dance',
  },
  {
    titleWordingKey: 'program_light_title',
    subtitleWordingKey: 'program_light_subtitle',
    image: imageLight,
    key: 'light',
  },
  {
    titleWordingKey: 'program_raise_title',
    subtitleWordingKey: 'program_raise_subtitle',
    image: imageRaise,
    key: 'raise',
  },
];
