import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SectionMissionComponent from './sectionMission';

const SectionMission = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { home },
      },
    }) => <SectionMissionComponent {...props} wordings={{ ...home }} />}
    query={graphql`
      query SectionMission {
        wordings {
          fr {
            home {
              mission_title
              mission_subtitle
              mission_desc
            }
          }
        }
      }
    `}
  />
);

export default SectionMission;
