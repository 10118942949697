import weatherImage from '../../../assets/img/home/weather-gif.gif';
import alarmImage from '../../../assets/img/home/trompette-gif.gif';

// eslint-disable-next-line import/prefer-default-export
export const SLIDES = [
  {
    textLines: [
      {
        type: 'h2',
        wordingKey: 'weather_title_line1',
      },
      {
        type: 'h1',
        wordingKey: 'weather_title_line2',
      },
      {
        type: 'h2',
        wordingKey: 'weather_title_line3',
      },
      {
        type: 'h1',
        wordingKey: 'weather_title_line4',
      },
    ],
    descWordingKey: 'weather_desc',
    className: '',
    imageClassName: 'weather',
    key: 'weather',
    image: weatherImage,
  },
  {
    textLines: [
      {
        type: 'h2',
        wordingKey: 'alarm_title_line1',
      },
      {
        type: 'h1',
        wordingKey: 'alarm_title_line2',
      },
      {
        type: 'h2',
        wordingKey: 'alarm_title_line3',
      },
      {
        type: 'h1',
        wordingKey: 'alarm_title_line4',
      },
    ],
    descWordingKey: 'alarm_desc',
    className: 'alarm',
    imageClassName: 'alarm',
    key: 'alarm',
    image: alarmImage,
  },
];
