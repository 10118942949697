import React from 'react';
import PropTypes from 'prop-types';

import './styles.styl';

import monimalzAnimation from '../../../assets/img/home/ready-monimalz.gif';
import monimalzBackground from '../../../assets/img/home/ready-monimalz.png';
import { ParallaxComponent } from '../../common';

const SectionReady = ({ wordings, isVisible }) => (
  <section className="ready">
    <ParallaxComponent index={10} isVisible={isVisible} skewable>
      <h1 className="left">{wordings.ready_title_left}</h1>
      <h1 className="rigth">{wordings.ready_title_right}</h1>
    </ParallaxComponent>

    <ParallaxComponent index={15} className="parallax">
      <img className="animation" src={monimalzAnimation} alt="" />
      <img className="background" src={monimalzBackground} alt="" />
    </ParallaxComponent>

    <div className="sneak-peek">
      <div className="wrapper">
        <h2 dangerouslySetInnerHTML={{ __html: wordings.ready_subtitle }} />
        <p>{wordings.ready_desc}</p>
      </div>
    </div>
  </section>
);

SectionReady.propTypes = {
  wordings: PropTypes.shape({}).isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default SectionReady;
