import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ParallaxComponent } from '../../common';

// Assets
import mobileCoverImage from '../../../assets/img/home/play-mobile-cover.png';
import desktopMp4Video from '../../../assets/videos/section-play/play-desktop.mp4';
import desktopWebMVideo from '../../../assets/videos/section-play/play-desktop.webm';
import desktopCoverImage from '../../../assets/img/home/play-desktop-cover.png';
import handImage from '../../../assets/img/home/play-hand.png';
import suricodeCard from '../../../assets/img/home/play-card-suricode.png';
import scCard from '../../../assets/img/home/play-card-cochon.png';
import johniCard from '../../../assets/img/home/play-card-smoothie.png';

// css
import './styles.styl';

const LETTER_INTERVAL_DURATION = 30;
const NB_INTERVAL_TO_WAIT = 40;

export default class SectionPlay extends Component {
  constructor(props) {
    super(props);
    this.words = props.wordings.play_animated_text.split('|'); // Split by word
    this.letterInterval = null;
    this.state = {
      increment: 1,
      currentWordIndex: 0,
      currentLetterIndex: 0,
    };
    this.intervalToWait = 0;

    this.updateText = this.updateText.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isVisible !== this.props.isVisible) {
      if (this.props.isVisible) {
        // Start interval
        this.letterInterval = window.setInterval(
          this.updateText,
          LETTER_INTERVAL_DURATION
        );
      } else if (this.letterInterval) {
        window.clearInterval(this.letterInterval);
        this.letterInterval = null;
      }
    }
  }

  updateText() {
    if (this.intervalToWait) {
      this.intervalToWait -= 1;
      return;
    }

    const { increment, currentWordIndex, currentLetterIndex } = this.state;

    let nextIncrement = increment;
    let nextWordIndex = currentWordIndex;
    let nextLetterIndex = currentLetterIndex + increment;

    if (nextLetterIndex > this.words[currentWordIndex].length) {
      // End of the word, go back
      nextIncrement = -increment;
      nextLetterIndex += nextIncrement; // TODO: add wait at the end
      this.intervalToWait = NB_INTERVAL_TO_WAIT;
    } else if (nextLetterIndex === 0) {
      // Change word
      nextWordIndex = (currentWordIndex + 1) % this.words.length;
      nextIncrement = -increment;
    }

    this.setState({
      increment: nextIncrement,
      currentLetterIndex: nextLetterIndex,
      currentWordIndex: nextWordIndex,
    });
  }

  renderAnimatedText() {
    const { wordings } = this.props;
    const { currentWordIndex, currentLetterIndex } = this.state;

    const animatedText = this.words[currentWordIndex].slice(
      0,
      currentLetterIndex
    );

    return (
      <h4>
        {wordings.play_animated_text_lead}
        <span className="type-anim">{animatedText}</span>
      </h4>
    );
  }

  renderCard(className, backgroundImage, parrallaxIndex) {
    const { isVisible } = this.props;

    return (
      <ParallaxComponent
        isVisible={isVisible}
        index={parrallaxIndex}
        className="mobile-hidden"
      >
        <div className={`card ${className}`}>
          <img className={className} src={backgroundImage} alt="" />
        </div>
      </ParallaxComponent>
    );
  }

  render() {
    const { wordings } = this.props;

    return (
      <section className="play">
        <div className="overflow-wrapper">
          <img
            src={mobileCoverImage}
            className="mobile-only mobile-cover"
            alt=""
          />

          <video autoPlay muted loop className="mobile-hidden">
            <source src={desktopMp4Video} type="video/mp4" />
            <source src={desktopWebMVideo} type="video/ogg" />
            <img src={desktopCoverImage} alt="Play" />
          </video>
          <img className="hand mobile-hidden" src={handImage} alt="" />
        </div>

        <ParallaxComponent index={15} skewable>
          <h1>{wordings.play_title}</h1>
        </ParallaxComponent>

        <div className="title">
          <ParallaxComponent index={10} skewable>
            {this.renderAnimatedText()}
          </ParallaxComponent>
        </div>
      
        {this.renderCard('red', suricodeCard, 10)}
        {this.renderCard('yellow', scCard, 15)}
        {this.renderCard('purple', johniCard, 8)}

        <div className="infos">
          <p>{wordings.play_desc}</p>
        </div>
      </section>
    );
  }
}

SectionPlay.propTypes = {
  wordings: PropTypes.shape({
    play_animated_text: PropTypes.string.isRequired,
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
};
