import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SectionStoryComponent from './sectionStory';

const SectionIntro = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { home },
      },
    }) => <SectionStoryComponent {...props} wordings={{ ...home }} />}
    query={graphql`
      query SectionStory {
        wordings {
          fr {
            home {
              story_title
              story_subtitle
              story_desc
            }
          }
        }
      }
    `}
  />
);

export default SectionIntro;
