import React from 'react';
import PropTypes from 'prop-types';

import { VideoComponent, ParallaxComponent } from '../../common';

import './styles.styl';

import mobileCoverImage from '../../../assets/img/home/message-mobile-cover.jpg';
import messageVideoMp4 from '../../../assets/videos/message.mp4';
import messageVideoWebM from '../../../assets/videos/message.webm';
import handImage from '../../../assets/img/home/hand-message.png';

const SectionMessage = ({ wordings, isVisible }) => (
  <section className="message">
    <div className="wrapper">
      <ParallaxComponent index={6} skewable isVisible={isVisible}>
        <h2>{wordings.message_title_line1}</h2>
      </ParallaxComponent>
      <ParallaxComponent index={10} skewable isVisible={isVisible}>
        <h1>{wordings.message_title_line2}</h1>
      </ParallaxComponent>
      <p dangerouslySetInnerHTML={{ __html: wordings.message_desc }} />
    </div>

    <img src={mobileCoverImage} alt="" className="mobile-cover mobile-only" />

    <div className="monimalz mobile-hidden">
      <VideoComponent className="" autoPlay muted loop isVisible={isVisible}>
        <source src={messageVideoMp4} type="video/mp4" />
        <source src={messageVideoWebM} type="video/ogg" />
      </VideoComponent>
    </div>
    <ParallaxComponent
      index={-4}
      className="mobile-hidden"
      isVisible={isVisible}
    >
      <img src={handImage} alt="" className="hand" />
    </ParallaxComponent>
  </section>
);

SectionMessage.propTypes = {
  wordings: PropTypes.shape({}).isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default SectionMessage;
