import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import HomeHeaderComponent from './homeHeader';

const HomeHeader = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { menu, home },
      },
    }) => <HomeHeaderComponent {...props} wordings={{ ...menu, ...home }} />}
    query={graphql`
      query HomeHeader {
        wordings {
          fr {
            menu {
              link_order
            }
            home {
              header_title
              header_subtitle
              header_disponibility
            }
          }
        }
      }
    `}
  />
);

export default HomeHeader;
