import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SectionProgramComponent from './sectionProgram';

const SectionProgram = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { home },
      },
    }) => <SectionProgramComponent {...props} wordings={{ ...home }} />}
    query={graphql`
      query SectionProgram {
        wordings {
          fr {
            home {
              program_title
              program_subtitle
              program_code_title
              program_code_subtitle
              program_dream_title
              program_dream_subtitle
              program_dance_title
              program_dance_subtitle
              program_light_title
              program_light_subtitle
              program_raise_title
              program_raise_subtitle
            }
          }
        }
      }
    `}
  />
);

export default SectionProgram;
