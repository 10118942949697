import React, { Component } from 'react';
import Proptypes from 'prop-types';
import { Link, withPrefix } from 'gatsby';
import lottie from 'lottie-web';

import { VideoComponent } from '../../common';

// CSS
import './styles.styl';

// Assets
import desktopIntroMp4 from '../../../assets/videos/intro_desktop.mp4';
import desktopIntroWebM from '../../../assets/videos/intro_desktop.webm';
import desktopIntroCover from '../../../assets/img/home/intro_desktop_cover.png';

export default class HomeHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileImageIndex: Math.ceil(Math.random() * 3),
    };

    this.logoAnimationRef = React.createRef();
    this.videoRef = React.createRef();
    this.logoAnimation = null;
  }

  componentDidMount() {
    this.logoAnimation = lottie.loadAnimation({
      container: this.logoAnimationRef.current,
      render: 'svg',
      loop: false,
      autoplay: false,
      path: withPrefix('/animations/logo.json'),
    });
  }

  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;

    if (prevProps.isVisible !== isVisible) {
      if (isVisible) {
        // Start the video and play the logo animations
        if (this.logoAnimation) {
          this.logoAnimation.play();
        }
        if (this.videoRef.current) {
          this.videoRef.current.play();
        }
      } else {
        if (this.logoAnimation) {
          this.logoAnimation.goToAndStop(0);
        }
        if (this.videoRef.current) {
          this.videoRef.current.pause();
        }
      }
    }
  }

  renderVideo() {
    const { isMobile, isVisible } = this.props;
    const { mobileImageIndex } = this.state;
    const className = isMobile
      ? `header-mobile-${mobileImageIndex}`
      : 'header-desktop';

    return (
      <div className={`fullscreen-video ${className}`}>
        <VideoComponent
          className="mobile-hidden"
          autoPlay
          muted
          loop
          isVisible={isVisible}
          defaultImg={desktopIntroCover}
        >
          <source src={desktopIntroMp4} type="video/mp4" />
          <source src={desktopIntroWebM} type="video/ogg" />
          <img src={desktopIntroCover} alt="Play" />
        </VideoComponent>
      </div>
    );
  }

  renderLogo() {
    return <div className="logo-player" ref={this.logoAnimationRef} />;
  }

  renderCta() {
    const { wordings } = this.props;

    return (
      <Link to="/product" className="button home-order-button">
        {wordings.link_order}
      </Link>
    );
  }

  render() {
    const { wordings, isLoading } = this.props;
    return (
      <header className={isLoading ? 'loading' : ''}>
        <h1 className="mobile-hidden">{wordings.header_title}</h1>
        {this.renderVideo()}
        {this.renderLogo()}
        <div className="button-container">
          <h3>{wordings.header_subtitle}</h3>
          <p
            dangerouslySetInnerHTML={{ __html: wordings.header_disponibility }}
            className="avaliable mobile-hidden"
          />
          {this.renderCta()}
        </div>
      </header>
    );
  }
}

HomeHeader.defaultProps = {
  isLoading: false,
};

HomeHeader.propTypes = {
  wordings: Proptypes.shape({}).isRequired,
  isMobile: Proptypes.bool.isRequired,
  isLoading: Proptypes.bool,
  isVisible: Proptypes.bool.isRequired,
};
