import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ParallaxComponent } from '../../common';

import './styles.styl';

import { SLIDES } from './constants';

export default class SectionWeather extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlideIndex: 0,
      currentSlideClass: '',
      previsousSlideIndex: -1,
      previousSlideClass: '',
    };

    this.interval = null;
    this.update = this.update.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;

    if (prevProps.isVisible !== isVisible) {
      if (isVisible) {
        this.interval = window.setInterval(this.update, 6000); // TODO: externalize
      }
      if (!isVisible) {
        window.clearInterval(this.interval);
        this.interval = null;
      }
    }
  }

  update() {
    const { currentSlideIndex } = this.state;
    const nextIndex = (currentSlideIndex + 1) % SLIDES.length;

    this.setState({
      currentSlideIndex: nextIndex,
      previousSlideIndex: currentSlideIndex,
      currentSlideClass: 'hidden down',
      previousSlideClass: 'up hidden',
    });

    window.setTimeout(() => {
      this.setState({
        previousSlideClass: 'hidden',
        currentSlideClass: '',
      });
    }, 1000);
  }

  renderLine({ type, wordingKey }, index) {
    const { wordings } = this.props;

    if (type === 'h1') {
      return (
        <h1 style={{ transitionDelay: `0.${index + 1}s` }} key={wordingKey}>
          {wordings[wordingKey]}
        </h1>
      );
    }
    return <h2 key={wordingKey}>{wordings[wordingKey]}</h2>;
  }

  renderSlide({ textLines, descWordingKey, key, className }, index) {
    const { wordings } = this.props;
    const {
      currentSlideIndex,
      currentSlideClass,
      previousSlideIndex,
      previousSlideClass,
    } = this.state;

    let aClassName = '';
    if (previousSlideIndex === index) {
      aClassName = previousSlideClass;
    } else if (currentSlideIndex === index) {
      aClassName = currentSlideClass;
    } else {
      aClassName = 'hidden';
    }

    return (
      <div className={`wrapper ${className} ${aClassName}`} key={key}>
        {textLines.map((line, index) => this.renderLine(line, index))}
        <p>{wordings[descWordingKey]}</p>
      </div>
    );
  }

  renderImages() {
    const { currentSlideIndex } = this.state;

    return (
      <ParallaxComponent index={5} className="gif-container">
        {SLIDES.map(({ imageClassName, image, key }, index) => (
          <img
            key={key}
            className={`gif ${imageClassName} ${
              index !== currentSlideIndex ? 'hidden' : ''
            }`}
            src={image}
            alt=""
          />
        ))}
      </ParallaxComponent>
    );
  }

  render() {
    return (
      <section className="weather">
        <div className="bottom mobile-hidden" />
        {SLIDES.map((slide, index) => this.renderSlide(slide, index))}
        {this.renderImages()}
      </section>
    );
  }
}

SectionWeather.propTypes = {
  wordings: PropTypes.shape({}).isRequired,
};
