/* eslint-disable import/prefer-default-export */
import HomeHeader from '../components/home/homeHeader';
import SectionIntro from '../components/home/sectionIntro';
import SectionStory from '../components/home/sectionStory';
import SectionPlay from '../components/home/sectionPlay';
import SectionDemo from '../components/home/sectionDemo';
import SectionReady from '../components/home/sectionReady';
import SectionHop from '../components/home/sectionHop';
import SectionBling from '../components/home/sectionBling';
import SectionMagot from '../components/home/sectionMagot';
import SectionMission from '../components/home/sectionMission';
import SectionWeather from '../components/home/sectionWeather';
import SectionMessage from '../components/home/sectionMessage';
import SectionProgram from '../components/home/sectionProgram';

export const HOME_SECTIONS = [
  { component: HomeHeader, key: 'header' },
  { component: SectionIntro, key: 'intro' },
  { component: SectionStory, key: 'story' },
  { component: SectionPlay, key: 'play' },
  { component: SectionDemo, key: 'demo' },
  { component: SectionReady, key: 'ready' },
  { component: SectionHop, key: 'hop' },
  { component: SectionBling, key: 'bling' },
  { component: SectionMagot, key: 'magot' },
  { component: SectionMission, key: 'mission' },
  { component: SectionWeather, key: 'weather' },
  { component: SectionMessage, key: 'message' },
  { component: SectionProgram, key: 'program' },
];
