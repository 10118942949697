import React from 'react';
import PropTypes from 'prop-types';

import { ParallaxComponent } from '../../common';

import './styles.styl';

import monimalzIntro from '../../../assets/img/home/intro-monimalz.png';

const SectionIntro = ({ wordings }) => (
  <section className="intro">
    <ParallaxComponent index={15} className="title-wrapper" skewable>
      <h1 className="left">{wordings.intro_title_left}</h1>
      <h1 className="right">{wordings.intro_title_right}</h1>
    </ParallaxComponent>

    <ParallaxComponent index={6} className="image-wrapper">
      <img src={monimalzIntro} alt="" />
    </ParallaxComponent>

    <ParallaxComponent className="sneak-peek" index={2}>
      <div className="wrapper">
        <h2 dangerouslySetInnerHTML={{ __html: wordings.intro_subtitle }} />
        <p>{wordings.intro_desc}</p>
      </div>
    </ParallaxComponent>
  </section>
);

SectionIntro.propTypes = {
  wordings: PropTypes.shape({}).isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default SectionIntro;
