import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

import { HOME_SECTIONS } from '../../constants/homeSections';

export default class HomeSectionWrapper extends Component {
  componentDidMount() {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = process.env.GATSBY_CRISP_CHAT;
    (function() {
      let d = document;
      let s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  }

  renderSection({ component, key }) {
    const { isMobile, welcomeAnimationPlayed } = this.props;
    const SectionComponent = component;

    return (
      <VisibilitySensor key={key} partialVisibility intervalDelay={1000}>
        {({ isVisible }) => (
          <SectionComponent
            isVisible={Boolean(isVisible) && welcomeAnimationPlayed}
            isMobile={isMobile}
          />
        )}
      </VisibilitySensor>
    );
  }

  render() {
    return (
      <Fragment>
        {HOME_SECTIONS.map(section => this.renderSection(section))}
      </Fragment>
    );
  }
}

HomeSectionWrapper.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  welcomeAnimationPlayed: PropTypes.bool.isRequired,
};
