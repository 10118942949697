import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import SectionDemoComponent from './sectionDemo';

const SectionDemo = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { home },
      },
    }) => <SectionDemoComponent {...props} wordings={{ ...home }} />}
    query={graphql`
      query SectionDemo {
        wordings {
          fr {
            home {
              demo_title
              demo_subtitle
            }
          }
        }
      }
    `}
  />
);

export default SectionDemo;
