/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Siema from 'siema';

import { PROGRAM_SLIDES } from './constants';

import './styles.styl';

export default class SectionProgram extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlideIndex: 0,
    };

    this.siemaRef = null;
    this.siema = null;
  }

  componentDidMount() {
    if (this.siemaRef) {
      this.siema = new Siema({
        selector: this.siemaRef,
        duration: 600,
        easing: 'cubic-bezier(.17,.67,.32,1.34)',
        perPage: 1,
        startIndex: 0,
        draggable: true,
        multipleDrag: true,
        threshold: 20,
        loop: true,
        rtl: false,
        onChange: () => {
          this.setState({ currentSlideIndex: this.siema.currentSlide });
        },
      });
    }
  }

  onDotClick(index) {
    if (this.siema) {
      this.siema.goTo(index);
    }
  }

  renderDots() {
    const { currentSlideIndex } = this.state;

    return (
      <div className="dots">
        {PROGRAM_SLIDES.map((slide, index) => (
          <div
            key={index}
            className={`dot ${currentSlideIndex === index ? 'active' : ''}`}
            onClick={() => {
              this.onDotClick(index);
            }}
          />
        ))}
      </div>
    );
  }

  renderSlide({ titleWordingKey, subtitleWordingKey, image, key }) {
    const { wordings } = this.props;

    return (
      <div className="wrapper slider" key={key}>
        <div className="">
          <h1>{wordings[titleWordingKey]}</h1>
        </div>
        <div className="">
          <h2>{wordings[subtitleWordingKey]}</h2>
        </div>

        <div className="">
          <img src={image} alt="" />
        </div>
      </div>
    );
  }

  render() {
    const { wordings } = this.props;

    return (
      <section className="program grabbable">
        <div className="wrapper title-wrapper">
          <div className="">
            <h2>{wordings.program_title}</h2>
          </div>
          <div className="">
            <h2 style={{ marginTop: '-10px', marginBottom: '30px' }}>
              {wordings.program_subtitle}
            </h2>
          </div>
        </div>
        <div className="siema-wrapper" ref={elem => (this.siemaRef = elem)}>
          {PROGRAM_SLIDES.map(slide => this.renderSlide(slide))}
        </div>
        {this.renderDots()}
      </section>
    );
  }
}

SectionProgram.propTypes = {
  wordings: PropTypes.shape({}).isRequired,
};
